/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react'
import { AutoColumn } from 'components/Column'
import { HideMedium, HideSmall, StyledInternalLink, TYPE } from 'theme'
import { BaseMainnetNetworkInfo, EthereumNetworkInfo, OpBnbMainnetNetworkInfo, SupportedNetwork, ZkSyncEraNetworkInfo } from 'constants/networks'
import { useSelector } from 'react-redux'
import { ProtocolData } from 'state/protocol/reducer'
import { AppState } from 'state'
import { ResponsiveRow, RowBetween, RowFixed } from 'components/Row'
import LineChart from 'components/LineChart/alt'
import { MonoSpace } from 'components/shared'
import { useProtocolChartData } from 'state/protocol/hooks'
import { unixToDate } from 'utils/date'
import BarChart from 'components/BarChart/alt'
import { ChartDayData, VolumeWindow } from 'types'
import { useTransformedVolumeData } from 'hooks/chart'
import { SmallOptionButton } from 'components/Button'
import { formatDollarAmount } from 'utils/numbers'
import { ChartWrapper } from 'pages/Home'
import { PageWrapper } from 'pages/styled'
import { DarkGreyCard } from 'components/Card'
import Percent from 'components/Percent'
import { notEmpty } from 'utils'
import { AllPools } from './widgets/AllPools'
import { QUERY_NETWORK } from 'constants/index'
import { StateUpdater } from 'pages/StateUpdater'
import { useGetDerpHolders } from 'hooks/useGetDerpHolders'
import { CombinedHoldersWrapper, HoldersWrapper, LogoWrapper, MultiHoldersWrapper, MultiInnerHoldersCountWrapper, MultiInnerHoldersWrapper, NetworkNameFlex } from './holders.stylings'
import { SupportedChainId } from 'constants/chains'

export default function Overview() {
  const [combinedTVL, setCombinedTVL] = useState<number>(0)
  const [combinedTVL24H, setCombinedTVL24H] = useState<number>(0)
  const [liquidityHover, setLiquidityHover] = useState<number | undefined>()
  const [combinedVolumeUSD, setCombinedVolumeUSD] = useState<number>(0)
  const [volumeHover, setVolumeHover] = useState<number | undefined>()
  const [leftLabel, setLeftLabel] = useState<string | undefined>()
  const [rightLabel, setRightLabel] = useState<string | undefined>()

  const [combinedVolumeChange, setCombinedVolumeChange] = useState<number>(0)
  const [combinedFeeUSD, setCombinedFeeUSD] = useState<number>(0)
  const [combinedFeeChange, setCombinedFeeChange] = useState<number>(0)

  const [aggregatedTVLUSDChange, setAggregatedTVLUSDChange] = useState<number>(0)

  const [cummulativeTradingVolumeUSD, setCummulativeTradingVolumeUSD] = useState<number>(0)
  const [cummulativeTradingVolumeHover, setCummulativeTradingVolumeHover] = useState<number | undefined>()

  const [cummulativeFeeUSD, setCummulativeFeeUSD] = useState<number>(0)
  const [cummulativeFeeUSDHover, setCummulativeFeeUSDHover] = useState<number | undefined>()

  const protocolData = useSelector((state: AppState) => state.protocol)

  useEffect(() => {
    let totalTVL = 0
    let totalTVL24H = 0
    let AggregatedTVLChange = 0
    QUERY_NETWORK.forEach((n) => {
      // current totalLocked
      totalTVL += protocolData[n].data?.tvlUSD || 0
      totalTVL24H += protocolData[n].data?.tvlUSD24H || 0
    })
    AggregatedTVLChange = (totalTVL - totalTVL24H) / (totalTVL24H === 0 ? 1 : totalTVL24H) * 100
    setCombinedTVL(totalTVL)
    setCombinedTVL24H(totalTVL24H)
    setAggregatedTVLUSDChange(AggregatedTVLChange)
    if (liquidityHover === undefined && protocolData) {
      setLiquidityHover(totalTVL)
    }
  }, [liquidityHover, protocolData])

  useEffect(() => {
    let totalVolumeUSD = 0
    let aggregatedVolumeChange = 0
    let combinedFeeUSD = 0
    let aggregateFeeChange = 0
    QUERY_NETWORK.forEach((n) => {
      totalVolumeUSD += protocolData[n].data?.volumeUSD || 0
      aggregatedVolumeChange += protocolData[n].data?.volumeUSDChange || 0
      combinedFeeUSD += protocolData[n].data?.feesUSD || 0
      aggregateFeeChange += protocolData[n].data?.feeChange || 0
    })
    setCombinedVolumeUSD(totalVolumeUSD)
    setCombinedVolumeChange(aggregatedVolumeChange)
    setCombinedFeeUSD(combinedFeeUSD)
    setCombinedFeeChange(aggregateFeeChange)
    if (volumeHover === undefined && protocolData) {
      setVolumeHover(totalVolumeUSD)
    }
  }, [volumeHover, protocolData])

  const formattedCombinedData = useMemo(() => {
    const dateToTvlMap: { [key: string]: number } = {};
    const dateToVolumeMap: { [key: string]: number } = {};
    const chartData: ChartDayData[] = []

    const combineddateToCummulativeTradingVolumeMap: { [key: string]: number } = {};
    const combineddateToCummulativeFeesMap: { [key: string]: number } = {};

    const zkSyncdateToCummulativeTradingVolumeMap: { [key: string]: number } = {};
    const zkSyncdateToCummulativeFeesMap: { [key: string]: number } = {};

    const basedateToCummulativeTradingVolumeMap: { [key: string]: number } = {};
    const basedateToCummulativeFeesMap: { [key: string]: number } = {};

    const opbnbdateToCummulativeTradingVolumeMap: { [key: string]: number } = {};
    const opbnbdateToCummulativeFeesMap: { [key: string]: number } = {};

    const dateToCummulativeFeesUSDMap: { [key: string]: number } = {};
    // let cumulativeTradingVolume = 0;
    // let cumulativeFeesUSD = 0;
    // let cumulativeVolume = 0;
    
    if (protocolData) {

      let zkSynccumulativeTradingVolume = 0;
      let zkSynccumulativeFees = 0;
      protocolData[process.env.REACT_APP_IS_TESTSITE === 'true' ? SupportedNetwork.ZKSYNC_TESTNET : SupportedNetwork.ZKSYNC_MAINNET].chartData?.forEach((c, _i) => {
        const date = unixToDate(c.date);
        const volumeUSD = c.volumeUSD;
        const feesUSD = c.feesUSD;
        zkSynccumulativeTradingVolume += volumeUSD;
        zkSynccumulativeFees += feesUSD;
          if (zkSyncdateToCummulativeTradingVolumeMap[date] === undefined) {
            zkSyncdateToCummulativeTradingVolumeMap[date] = zkSynccumulativeTradingVolume;
            zkSyncdateToCummulativeFeesMap[date] = zkSynccumulativeFees
          } else {
            zkSyncdateToCummulativeTradingVolumeMap[date] += volumeUSD; // Add the current day's TVL to the existing cumulative TVL
            zkSyncdateToCummulativeFeesMap[date] += feesUSD; // Add the current day's TVL to the existing cumulative TVL
          }
      })

      let basecumulativeTradingVolume = 0;
      let basecumulativeFees = 0;
      protocolData[process.env.REACT_APP_IS_TESTSITE === 'true' ? SupportedNetwork.BASE_TESTNET : SupportedNetwork.BASE_MAINNET].chartData?.forEach((c, _i) => {
        const date = unixToDate(c.date);
        const volumeUSD = c.volumeUSD;
        const feesUSD = c.feesUSD;
        basecumulativeTradingVolume += volumeUSD;
        basecumulativeFees += feesUSD;
          if (basedateToCummulativeTradingVolumeMap[date] === undefined) {
            basedateToCummulativeTradingVolumeMap[date] = basecumulativeTradingVolume;
            basedateToCummulativeFeesMap[date] = basecumulativeFees
          } else {
            basedateToCummulativeTradingVolumeMap[date] += volumeUSD; // Add the current day's TVL to the existing cumulative TVL
            basedateToCummulativeFeesMap[date] += volumeUSD; // Add the current day's TVL to the existing cumulative TVL
          }
      })

      let opbnbcumulativeTradingVolume = 0;
      let opbnbcumulativeFees = 0;
      protocolData[process.env.REACT_APP_IS_TESTSITE === 'true' ? SupportedNetwork.OPBNB_TESTNET : SupportedNetwork.OPBNB_MAINNET].chartData?.forEach((c, _i) => {
        const date = unixToDate(c.date);
        const volumeUSD = c.volumeUSD;
        const feesUSD = c.feesUSD;
        opbnbcumulativeTradingVolume += volumeUSD;
        opbnbcumulativeFees += feesUSD;
          if (opbnbdateToCummulativeTradingVolumeMap[date] === undefined) {
            opbnbdateToCummulativeTradingVolumeMap[date] = opbnbcumulativeTradingVolume;
            opbnbdateToCummulativeFeesMap[date] = opbnbcumulativeFees;
          } else {
            opbnbdateToCummulativeTradingVolumeMap[date] += volumeUSD; // Add the current day's TVL to the existing cumulative TVL
            opbnbdateToCummulativeFeesMap[date] += feesUSD; // Add the current day's TVL to the existing cumulative TVL
          }
      })

      // Combine zkSync map
      Object.keys(zkSyncdateToCummulativeTradingVolumeMap).forEach((date) => {
          combineddateToCummulativeTradingVolumeMap[date] = zkSyncdateToCummulativeTradingVolumeMap[date];
          combineddateToCummulativeFeesMap[date] = zkSyncdateToCummulativeFeesMap[date];
      });
      
      // Combine base map
      Object.keys(basedateToCummulativeTradingVolumeMap).forEach((date) => {
          if (combineddateToCummulativeTradingVolumeMap[date] === undefined) {
              combineddateToCummulativeTradingVolumeMap[date] = basedateToCummulativeTradingVolumeMap[date];
          } else {
              combineddateToCummulativeTradingVolumeMap[date] += basedateToCummulativeTradingVolumeMap[date];
          }

          if (combineddateToCummulativeFeesMap[date] === undefined) {
            combineddateToCummulativeFeesMap[date] = basedateToCummulativeFeesMap[date];
          } else {
            combineddateToCummulativeFeesMap[date] += basedateToCummulativeFeesMap[date];
          }
      });
      
      // Combine opbnb map
      Object.keys(opbnbdateToCummulativeTradingVolumeMap).forEach((date) => {
          if (combineddateToCummulativeTradingVolumeMap[date] === undefined) {
              combineddateToCummulativeTradingVolumeMap[date] = opbnbdateToCummulativeTradingVolumeMap[date];
          } else {
              combineddateToCummulativeTradingVolumeMap[date] += opbnbdateToCummulativeTradingVolumeMap[date];
          }

          if (combineddateToCummulativeFeesMap[date] === undefined) {
            combineddateToCummulativeFeesMap[date] = opbnbdateToCummulativeFeesMap[date];
          } else {
            combineddateToCummulativeFeesMap[date] += opbnbdateToCummulativeFeesMap[date];
          }
      });

      QUERY_NETWORK.forEach((n, i) => {
        protocolData[n].chartData?.forEach((c, _i) => {
          const date = unixToDate(c.date);
          const tvlUSD = c.tvlUSD;
          const volumeUSD = c.volumeUSD;
          const feesUSD = c.feesUSD;
      
          if (dateToTvlMap[date] === undefined) {
            dateToTvlMap[date] = tvlUSD;
          } else {
            dateToTvlMap[date] += tvlUSD;
          }

          if (dateToVolumeMap[date] === undefined) {
            dateToVolumeMap[date] = volumeUSD;
          } else {
            dateToVolumeMap[date] += volumeUSD;
          }
        });
      });
      const formattedTvlData = Object.entries(dateToTvlMap).map(([time, value]) => ({
        time,
        value,
      }));

      const formattedVolumeData = Object.entries(dateToVolumeMap).map(([time, value]) => ({
        time,
        value,
      }));

      const formattedCummulativeTvlData = Object.entries(combineddateToCummulativeTradingVolumeMap).map(([time, value]) => ({
        time,
        value,
      }));

      const formattedCummulativeFeesUSDData = Object.entries(combineddateToCummulativeFeesMap).map(([time, value]) => ({
        time,
        value,
      }));

      return {
        formattedTvlData,
        formattedVolumeData,
        formattedCummulativeTvlData,
        formattedCummulativeFeesUSDData,
      }
    } else {
      return {
        formattedTvlData: [],
        formattedVolumeData: [],
        formattedCummulativeTvlData: [],
        formattedCummulativeFeesUSDData: [],
      }
    }
  }, [protocolData])

  useEffect(() => {
    const lastIndex = formattedCombinedData.formattedCummulativeTvlData.length - 1
    const totalCummulative = formattedCombinedData.formattedCummulativeTvlData[lastIndex]?.value || 0
    setCummulativeTradingVolumeUSD(totalCummulative)
    if(cummulativeTradingVolumeHover === undefined) {
      setCummulativeTradingVolumeHover(totalCummulative)
    }
  }, [cummulativeTradingVolumeHover, formattedCombinedData.formattedCummulativeTvlData])

  useEffect(() => {
    const lastIndex = formattedCombinedData.formattedCummulativeFeesUSDData.length - 1
    const totalCummulativeFeesUSD = formattedCombinedData.formattedCummulativeFeesUSDData[lastIndex]?.value || 0
    setCummulativeFeeUSD(totalCummulativeFeesUSD)
    if(cummulativeFeeUSDHover === undefined) {
      setCummulativeFeeUSDHover(totalCummulativeFeesUSD)
    }
  }, [cummulativeFeeUSDHover, formattedCombinedData.formattedCummulativeFeesUSDData])

  const [volumeWindow, setVolumeWindow] = useState(VolumeWindow.weekly)

  const combinedChartData = useMemo(() => {
    if (protocolData) {
      const mergedData: ChartDayData[] = [];
  
      QUERY_NETWORK.forEach((n) => {
        if (protocolData[n] && protocolData[n].chartData) {
          mergedData.push(...(protocolData[n].chartData as ChartDayData[]));
        }
      });
  
      // Sort the combinedData array by date
      mergedData.sort((a, b) => a.date - b.date);
  
      return mergedData;
    }
  
    return [];
  }, [protocolData]);

  const weeklyVolumeData = useTransformedVolumeData(combinedChartData, 'week')
  const monthlyVolumeData = useTransformedVolumeData(combinedChartData, 'month')

  const shouldFetch = useMemo(() => {

    // eslint-disable-next-line prefer-const
    const state1 = protocolData[SupportedNetwork.ZKSYNC_MAINNET].data !== undefined
    const state2 = protocolData[SupportedNetwork.BASE_MAINNET].data !== undefined
    const state3 = protocolData[SupportedNetwork.OPBNB_MAINNET].data !== undefined
    // const state = Object.keys(protocolData).filter((networkId: string) => QUERY_NETWORK.includes(Number(networkId))).every((i) => protocolData[i].data !== undefined && protocolData[i].chartData !== undefined)
    if(state1 && state2 && state3) {
      return false
    } else {
      return true
    }
  }, [protocolData])



  return (
    <PageWrapper>
      <Holders />
      <AutoColumn gap='16px'>
        <TYPE.main>DerpDeX Overview (Across All Supported Chains)</TYPE.main>
        <ResponsiveRow>
          <ChartWrapper>
            <LineChart
              data={formattedCombinedData.formattedTvlData}
              height={220}
              minHeight={332}
              value={liquidityHover}
              label={leftLabel}
              color={'#A372FF'}
              secondaryColor={'#46C9D2'}
              setValue={setLiquidityHover}
              setLabel={setLeftLabel}
              topLeft={
                <AutoColumn gap="4px">
                  <TYPE.mediumHeader fontSize="16px">TVL</TYPE.mediumHeader>
                  <TYPE.largeHeader fontSize="32px">
                    <MonoSpace>{combinedTVL && !liquidityHover ? formatDollarAmount(combinedTVL, 2, true) : formatDollarAmount(liquidityHover, 2, true)}</MonoSpace>
                  </TYPE.largeHeader>
                  <TYPE.main fontSize="12px" height="14px">
                    {leftLabel ? <MonoSpace>{leftLabel} (UTC)</MonoSpace> : null}
                  </TYPE.main>
                </AutoColumn>
              }
            />
          </ChartWrapper>
          <ChartWrapper>
            <BarChart
              height={220}
              minHeight={332}
              data={
                volumeWindow === VolumeWindow.monthly
                  ? monthlyVolumeData
                  : volumeWindow === VolumeWindow.weekly
                  ? weeklyVolumeData
                  : formattedCombinedData.formattedVolumeData
              }
              setValue={setVolumeHover}
              setLabel={setRightLabel}
              value={volumeHover}
              label={rightLabel}
              color={'#A372FF'}
              secondaryColor={'#46C9D2'}
              activeWindow={volumeWindow}
              topRight={
                <RowFixed style={{ marginLeft: '-40px', marginTop: '8px' }}>
                  <SmallOptionButton
                    active={volumeWindow === VolumeWindow.daily}
                    onClick={() => setVolumeWindow(VolumeWindow.daily)}
                  >
                    D
                  </SmallOptionButton>
                  <SmallOptionButton
                    active={volumeWindow === VolumeWindow.weekly}
                    style={{ marginLeft: '8px' }}
                    onClick={() => setVolumeWindow(VolumeWindow.weekly)}
                  >
                    W
                  </SmallOptionButton>
                  <SmallOptionButton
                    active={volumeWindow === VolumeWindow.monthly}
                    style={{ marginLeft: '8px' }}
                    onClick={() => setVolumeWindow(VolumeWindow.monthly)}
                  >
                    M
                  </SmallOptionButton>
                </RowFixed>
              }
              topLeft={
                <AutoColumn gap="4px">
                  <TYPE.mediumHeader fontSize="16px">Volume 24H</TYPE.mediumHeader>
                  <TYPE.largeHeader fontSize="32px">
                  <MonoSpace>{combinedVolumeUSD && !volumeHover ? formatDollarAmount(combinedVolumeUSD, 2, true) : formatDollarAmount(volumeHover, 2, true)}</MonoSpace>
                  </TYPE.largeHeader>
                  <TYPE.main fontSize="12px" height="14px">
                    {rightLabel ? <MonoSpace>{rightLabel} (UTC)</MonoSpace> : null}
                  </TYPE.main>
                </AutoColumn>
              }
            />
          </ChartWrapper>
        </ResponsiveRow>
        {/* <TYPE.main>Mean (Average)</TYPE.main>
        <ResponsiveRow>
          <DarkGreyCard>
            <RowBetween>
              <RowFixed>
                <RowFixed mr="20px">
                  <TYPE.main mr="4px">Average Volume 24H: </TYPE.main>
                  <TYPE.label mr="4px">{formatDollarAmount(combinedVolumeUSD / QUERY_NETWORK.length)}</TYPE.label>
                  <Percent value={combinedVolumeChange / QUERY_NETWORK.length} wrap={true} />
                </RowFixed>
                <RowFixed mr="20px">
                  <TYPE.main mr="4px">Average Fees 24H: </TYPE.main>
                  <TYPE.label mr="4px">{formatDollarAmount(combinedFeeUSD / QUERY_NETWORK.length)}</TYPE.label>
                  <Percent value={combinedFeeChange / QUERY_NETWORK.length} wrap={true} />
                </RowFixed>
                <HideMedium>
                  <RowFixed mr="20px">
                    <TYPE.main mr="4px">Average TVL: </TYPE.main>
                    <TYPE.label mr="4px">{formatDollarAmount(combinedTVL / QUERY_NETWORK.length)}</TYPE.label>
                    <TYPE.main></TYPE.main>
                    <Percent value={combinedTVLUSDChange / QUERY_NETWORK.length} wrap={true} />
                  </RowFixed>
                </HideMedium>
              </RowFixed>
            </RowBetween>
          </DarkGreyCard>
        </ResponsiveRow> */}
        <TYPE.main>Cumulative</TYPE.main>
        <ResponsiveRow>
          <ChartWrapper>
            <LineChart
              data={formattedCombinedData.formattedCummulativeTvlData}
              height={220}
              minHeight={332}
              value={cummulativeTradingVolumeHover}
              label={leftLabel}
              color={'#A372FF'}
              secondaryColor={'#46C9D2'}
              setValue={setCummulativeTradingVolumeHover}
              setLabel={setLeftLabel}
              topLeft={
                <AutoColumn gap="4px">
                  <TYPE.mediumHeader fontSize="16px">Volume (Cumulative)</TYPE.mediumHeader>
                  <TYPE.largeHeader fontSize="32px">
                    <MonoSpace>{cummulativeTradingVolumeUSD && !cummulativeTradingVolumeHover ? formatDollarAmount(cummulativeTradingVolumeUSD, 2, true) : formatDollarAmount(cummulativeTradingVolumeHover, 2, true)}</MonoSpace>
                  </TYPE.largeHeader>
                  <TYPE.main fontSize="12px" height="14px">
                    {leftLabel ? <MonoSpace>{leftLabel} (UTC)</MonoSpace> : null}
                  </TYPE.main>
                </AutoColumn>
              }
            />
          </ChartWrapper>
          <ChartWrapper>
            <LineChart
              data={formattedCombinedData.formattedCummulativeFeesUSDData}
              height={220}
              minHeight={332}
              value={cummulativeFeeUSDHover}
              label={leftLabel}
              color={'#A372FF'}
              secondaryColor={'#46C9D2'}
              setValue={setCummulativeFeeUSDHover}
              setLabel={setLeftLabel}
              topLeft={
                <AutoColumn gap="4px">
                  <TYPE.mediumHeader fontSize="16px">Fees (Cumulative)</TYPE.mediumHeader>
                  <TYPE.largeHeader fontSize="32px">
                    <MonoSpace>{cummulativeFeeUSD && !cummulativeFeeUSDHover ? formatDollarAmount(cummulativeFeeUSD, 2, true) : formatDollarAmount(cummulativeFeeUSDHover, 2, true)}</MonoSpace>
                  </TYPE.largeHeader>
                  <TYPE.main fontSize="12px" height="14px">
                    {leftLabel ? <MonoSpace>{leftLabel} (UTC)</MonoSpace> : null}
                  </TYPE.main>
                </AutoColumn>
              }
            />
          </ChartWrapper>
        </ResponsiveRow>
        <TYPE.main>Summary</TYPE.main>
        <HideSmall>
          <DarkGreyCard>
            <RowBetween>
              <RowFixed>
                <RowFixed mr="20px">
                  <TYPE.main mr="4px">Volume 24H: </TYPE.main>
                  <TYPE.label mr="4px">{formatDollarAmount(combinedVolumeUSD)}</TYPE.label>
                  <Percent value={combinedVolumeChange} wrap={true} />
                </RowFixed>
                <RowFixed mr="20px">
                  <TYPE.main mr="4px">Fees 24H: </TYPE.main>
                  <TYPE.label mr="4px">{formatDollarAmount(combinedFeeUSD)}</TYPE.label>
                  <Percent value={combinedFeeChange} wrap={true} />
                </RowFixed>
                <HideMedium>
                  <RowFixed mr="20px">
                    <TYPE.main mr="4px">TVL: </TYPE.main>
                    <TYPE.label mr="4px">{formatDollarAmount(combinedTVL)}</TYPE.label>
                    <TYPE.main></TYPE.main>
                    <Percent value={aggregatedTVLUSDChange} wrap={true} />
                  </RowFixed>
                </HideMedium>
              </RowFixed>
            </RowBetween>
          </DarkGreyCard>
        </HideSmall>
        {/* <RowBetween>
          <TYPE.main>Top Tokens</TYPE.main>
          <StyledInternalLink to="tokens">Explore</StyledInternalLink>
        </RowBetween>
        <TokenTable tokenDatas={formattedTokens} /> */}
        <AllPools shouldFetch={shouldFetch} />
      </AutoColumn>
      <StateUpdater />
    </PageWrapper>
  )
}

const Holders = () => {
  const { holdersState } = useGetDerpHolders()

  const combinedDerpDEXHolders = useMemo(() => {
    // Object.entries(holdersState).forEach(([key, value]) => {
    //   const chainId = Number(key) as SupportedChainId;
    //   console.log('val', value)
    //   // Do something with value.derpholders
    // });
    return {
      DERP: Object.entries(holdersState).reduce((acc, [key, value]) => {
          // Assuming derpholders is a number property
          return acc + value.derpholders;
        }, 0),
      XDERP: Object.entries(holdersState).reduce((acc, [key, value]) => {
        // Assuming derpholders is a number property
        return acc + value.xderpholders;
      }, 0),
    }
  }, [holdersState]);

  return <>
    <TYPE.main>DerpDeX Holders</TYPE.main>
    <HoldersWrapper>
      <CombinedHoldersWrapper>
        <TYPE.largeHeader>DERP holders: {combinedDerpDEXHolders.DERP}</TYPE.largeHeader>
        <TYPE.largeHeader>xDERP holders: {combinedDerpDEXHolders.XDERP}</TYPE.largeHeader>
      </CombinedHoldersWrapper>
      <MultiHoldersWrapper>
        <MultiInnerHoldersWrapper>
          <NetworkNameFlex>
            <TYPE.body>Ethereum</TYPE.body>
            <LogoWrapper src={EthereumNetworkInfo.imageURL} />
          </NetworkNameFlex>
          <MultiInnerHoldersCountWrapper>
            <TYPE.small>DERP ({holdersState[1].derpholders})</TYPE.small>
            <TYPE.small>xDERP ({holdersState[1].xderpholders})</TYPE.small>
          </MultiInnerHoldersCountWrapper>
        </MultiInnerHoldersWrapper>
        <MultiInnerHoldersWrapper>
          <NetworkNameFlex>
            <TYPE.body style={{ whiteSpace: 'nowrap' }}>zkSync Era</TYPE.body>
            <LogoWrapper src={ZkSyncEraNetworkInfo.imageURL} />
          </NetworkNameFlex>
          <MultiInnerHoldersCountWrapper>
            <TYPE.small>DERP ({holdersState[324].derpholders})</TYPE.small>
            <TYPE.small>xDERP ({holdersState[324].xderpholders})</TYPE.small>
          </MultiInnerHoldersCountWrapper>
        </MultiInnerHoldersWrapper>
        <MultiInnerHoldersWrapper>
          <NetworkNameFlex>
            <TYPE.body style={{ whiteSpace: 'nowrap' }}>Base</TYPE.body>
            <LogoWrapper src={BaseMainnetNetworkInfo.imageURL} />
          </NetworkNameFlex>
          <MultiInnerHoldersCountWrapper>
            <TYPE.small>DERP ({holdersState[8453].derpholders})</TYPE.small>
            <TYPE.small>xDERP ({holdersState[8453].xderpholders})</TYPE.small>
          </MultiInnerHoldersCountWrapper>
        </MultiInnerHoldersWrapper>
        <MultiInnerHoldersWrapper>
          <NetworkNameFlex>
            <TYPE.body style={{ whiteSpace: 'nowrap' }}>opBNB</TYPE.body>
            <LogoWrapper src={OpBnbMainnetNetworkInfo.imageURL} />
          </NetworkNameFlex>
          <MultiInnerHoldersCountWrapper>
            <TYPE.small>DERP ({holdersState[204].derpholders})</TYPE.small>
            <TYPE.small>xDERP ({holdersState[204].xderpholders})</TYPE.small>
          </MultiInnerHoldersCountWrapper>
        </MultiInnerHoldersWrapper>
      </MultiHoldersWrapper>
    </HoldersWrapper>
  </>
}
/* eslint-disable prettier/prettier */
import { SupportedChainId } from '@derpdex/sdk-core'
import { Contract, ethers } from 'ethers'
import { useEffect, useState } from 'react'

export const DERP_ADDRESSES_BY_CHAINID: { [key: number]: string } = {
  [SupportedChainId.MAINNET]: '0x5dfc78c4d073fd343bc6661668948178522a0de5',
  [SupportedChainId.GOERLI]: '0xE564Cc37FbAb4B4b71BD76AD8EB0AF6726B6b7D2',
  [SupportedChainId.OPBNB_MAINNET]: '0xebb78043e29f4af24e6266a7d142f5a08443969e',
  [SupportedChainId.OPBNB_TESTNET]: '0xac465de4d7Fbdc2e3c33f303147298200357CbFE',
  [SupportedChainId.BASE_MAINNET]: '0xebb78043e29f4af24e6266a7d142f5a08443969e',
  [SupportedChainId.BASE_TESTNET]: '0xac465de4d7Fbdc2e3c33f303147298200357CbFE',
  [SupportedChainId.ZKSYNC_MAINNET]: '0x0bf4cb727b3f8092534d793893b2cc3348963dbf',
  [SupportedChainId.ZKSYNC_TESTNET]: '0xB5Ae4151A015F89d81a37EA4088AF8a4aDC56961',
}

const MAINNET_CHAINID = [
  SupportedChainId.MAINNET,
  SupportedChainId.OPBNB_MAINNET,
  SupportedChainId.BASE_MAINNET,
  SupportedChainId.ZKSYNC_MAINNET,
]

// const test = process.env.REACT_APP_IS_TESTSITE === 'true' ? MAINNET_CHAINID

export const XDERP_ADDRESSES_BY_CHAINID: { [key: number]: string } = {
  [SupportedChainId.MAINNET]: '0xfb13dcf64e14ac4403ed52cd690ea4641d90c4c6',
  [SupportedChainId.GOERLI]: '0x3E120082907912667c1b4444cE7Cb74cf91535f9',
  [SupportedChainId.OPBNB_MAINNET]: '0x983ba88d66291d5a62684f1fe01ff186f97ab455',
  [SupportedChainId.OPBNB_TESTNET]: '0x9F7999406D579b80b8ddabB8B106F4a22D057788',
  [SupportedChainId.BASE_MAINNET]: '0x85fb47a24afec3cba80d439b8cb108121de333d9',
  [SupportedChainId.BASE_TESTNET]: '0x93B569efC1487a3CA0b4F52d0762b81018E3ab49',
  [SupportedChainId.ZKSYNC_MAINNET]: '0x3239b2fb367243d827fea1e460f016e15797d7ca',
  [SupportedChainId.ZKSYNC_TESTNET]: '0x22032C8170BaEA3c425010d1cb0095d225B266B9',
}

const SUBGRAPH_BY_CHAINID : { [key: number]: string | undefined } = {
  [SupportedChainId.MAINNET]: 'https://api.studio.thegraph.com/query/55824/erc20/v19',
  [SupportedChainId.OPBNB_MAINNET]: 'https://open-platform-ap.nodereal.io/1d223794a8a841a78994ddeb7c1bb966/opbnb-mainnet-graph-query/subgraphs/name/opbnb-derpdex-staking',
  [SupportedChainId.BASE_MAINNET]: 'https://api.studio.thegraph.com/query/55824/base-derp-holders/v1',
  [SupportedChainId.ZKSYNC_MAINNET]: 'https://api.studio.thegraph.com/query/55824/zksync-derp-holders/v1',
}

interface DEFAULT_STATE {
  derpholders: number;
  xderpholders: number;
  isLoading: boolean,
  isSuccess: boolean,
  isError: boolean,
}
const default_state: DEFAULT_STATE = {
  derpholders: 0,
  xderpholders: 0,
  isLoading: false,
  isSuccess: false,
  isError: false,
}

interface DERP_HOLDERS {
  [SupportedChainId.MAINNET]: DEFAULT_STATE,
  [SupportedChainId.OPBNB_MAINNET]: DEFAULT_STATE,
  [SupportedChainId.BASE_MAINNET]: DEFAULT_STATE,
  [SupportedChainId.ZKSYNC_MAINNET]: DEFAULT_STATE
}
export const useGetDerpHolders = () => {
  const [data, setData] = useState<DERP_HOLDERS>({
    [SupportedChainId.MAINNET]: default_state,
    [SupportedChainId.OPBNB_MAINNET]: default_state,
    [SupportedChainId.BASE_MAINNET]: default_state,
    [SupportedChainId.ZKSYNC_MAINNET]: default_state
  })

  const onGetHolders = async (chainToDispatch: number) => {
    try {
      setData((prev) => ({
        ...prev,
        [chainToDispatch]: { ...default_state, isLoading: true }
      }))
      console.log('[Starting]')
      const pageLimit = 100000
      const skipSize = 500
      const initialSize = 0
      // process.env.REACT_APP_IS_TESTSITE === 'true'
      let DERP_DATA: any[] = []
      let XDERP_DATA: any[] = []

      if (!SUBGRAPH_BY_CHAINID[chainToDispatch]) throw 'Subgraph undefined'

      for (let i = initialSize; i < pageLimit; i += skipSize) {
        const response = await fetch(SUBGRAPH_BY_CHAINID[chainToDispatch]!, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            query: ` {
              derpResults: tokenHolders (first: 500, skip: ${i}, where: { balance_not: "0", token_: {name_starts_with: "DERP" } }) {
                holder
                balance
                token {
                  name
                }
              }
              xderpResults: tokenHolders (first: 500, skip: ${i}, where: { balance_not: "0", token_: {name_starts_with: "XDERP" } }) {
                holder
                balance
                token {
                  name
                }
              }
            }`
          })
        })

        if (response.ok) {
          const result = await response.json()
          console.log('result', result)
          if (result.data.derpResults.length > 0) {
            DERP_DATA = [...DERP_DATA, ...result.data.derpResults]
          }

          if (result.data.xderpResults.length > 0) {
            XDERP_DATA = [...XDERP_DATA, ...result.data.xderpResults]
          }

          if (!(result.data.derpResults.length > 0) && !(result.data.xderpResults.length > 0)) {
            break
          }
        } else {
          break
        }
      }

      // console.log('DATA', DERP_DATA)
      // console.log('DATA', XDERP_DATA)
      setData((prev) => ({
        ...prev,
        [chainToDispatch]: { ...default_state, derpholders: DERP_DATA.length, xderpholders: XDERP_DATA.length, isLoading: false }
      }))
      
    } catch (err) {
      console.log('[Err onGetHolders]', err)
    }
  }

  const dispatch = async () => {
    try {
      const promises = []
      for (const chainSubgraph of Object.keys(SUBGRAPH_BY_CHAINID)) {
        console.log('chainSubgraph', chainSubgraph)
        if (SUBGRAPH_BY_CHAINID[Number(chainSubgraph)])
          promises.push(() => onGetHolders(Number(chainSubgraph)))
      }

      console.log('promises', promises)

    // Now, call Promise.all with the array of functions
    await Promise.all(promises.map(func => func()));
    } catch (error) {
      console.log('[Error dispatch]', error)
    }
  }

  console.log('finals', data)

  useEffect(() => {
    dispatch()
  }, [])
  
  return {
    holdersState: data
  }
}

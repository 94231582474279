/* eslint-disable prettier/prettier */
import React, { useEffect, useMemo, useState } from 'react'
import { PageWrapper } from 'pages/styled'
import { AutoColumn } from 'components/Column'
import { TYPE } from 'theme'
import { useAllNetworkPoolData, useAllPoolData, usePoolDatas } from 'state/pools/hooks'

import PoolTableByNetwork, { ExtendedPoolData } from 'components/pools/PoolTableByNetwork'
import { QUERY_NETWORK } from '../../../constants'
// import TopPoolMovers from 'components/pools/TopPoolMovers'

export const AllPools = ({ shouldFetch }: { shouldFetch: boolean }) => {
  const [allPools, setAllPools] = useState<ExtendedPoolData[]>([])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // get all the pool datas that exist
  const allPoolData = useAllNetworkPoolData()

  // const poolDatas = useMemo(() => {
  //   const finalDatas: ExtendedPoolData[] = []
  //   QUERY_NETWORK.forEach((n) => {
  //     Object.values(allPoolData.byAddress[n])
  //     .forEach((p) => {
  //       if(p.data)
  //       finalDatas.push({
  //         networkId: n,
  //         ...p.data
  //       })
  //     })
  //   })
  //   return finalDatas
  //   // shouldFetch for change detection
  // }, [allPoolData, shouldFetch])

  useEffect(() => {
    const finalDatas: ExtendedPoolData[] = []
    QUERY_NETWORK.forEach((n) => {
      Object.values(allPoolData.byAddress[n])
      .forEach((p) => {
        if(p.data)
        finalDatas.push({
          networkId: n,
          ...p.data
        })
      })
    })
    setAllPools(finalDatas)
  }, [allPoolData, shouldFetch])

  return (
    <>
      <AutoColumn gap="lg">
        <TYPE.main>All Pools</TYPE.main>
        <PoolTableByNetwork poolDatas={allPools} />
      </AutoColumn>
    </>
  )
}
export enum SupportedChainId {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GOERLI = 5,
  KOVAN = 42,

  ARBITRUM_ONE = 42161,
  ARBITRUM_RINKEBY = 421611,
  OPTIMISM = 10,
  OPTIMISTIC_KOVAN = 69,

  CELO = 42220,

  ZKSYNC_TESTNET = 280,
  ZKSYNC_MAINNET = 324,

  BASE_TESTNET = 84531,
  BASE_MAINNET = 8453,

  OPBNB_TESTNET = 5611,
  OPBNB_MAINNET = 204,
}

/* eslint-disable prettier/prettier */
import styled from "styled-components"

export const HoldersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 228px;
  border-radius: 16px;
  min-height: 128px;
  background: #F7F8FA;
  padding: 1rem;
  gap: 18px;

  align-items: center;
  justify-content: center;

  margin-top: 8px;
  margin-bottom: 16px;
`

export const CombinedHoldersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  align-items: center;
  justify-content: center;
  gap: 18px;
`

export const MultiHoldersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  align-items: center;
  justify-content: center;
  gap: 18px;
`

export const MultiInnerHoldersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;

  align-items: center;
  justify-content: center;
  gap: 8px;
`

export const MultiInnerHoldersCountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  align-items: center;
  justify-content: center;
  gap: 6px;
` 

export const LogoWrapper = styled.img`
  width: 20px;
  height: 20px;
`

export const NetworkNameFlex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`
import OPTIMISM_LOGO_URL from '../assets/images/optimism.svg'
import ARBITRUM_LOGO_URL from '../assets/images/arbitrum.svg'
import ETHEREUM_LOGO_URL from '../assets/images/ethereum-logo.png'
import POLYGON_LOGO_URL from '../assets/images/polygon-logo.png'
import CELO_LOGO_URL from '../assets/images/celo-logo.svg'
import BNB_LOGO_URL from '../assets/images/bnb-logo.svg'
import ZKSYNC_LOGO_URL from '../assets/images/zksync-era-logo.svg'
import BASE_LOGO_URL from '../assets/images/base-logo.svg'

import { SupportedChainId } from '@derpdex/sdk-core'

export enum SupportedNetwork {
  ETHEREUM,
  ARBITRUM,
  OPTIMISM,
  POLYGON,
  CELO,
  BNB,
  ZKSYNC_TESTNET,
  ZKSYNC_MAINNET,
  BASE_TESTNET,
  BASE_MAINNET,
  OPBNB_TESTNET,
  OPBNB_MAINNET,
}

export type NetworkInfo = {
  chainId: SupportedChainId
  id: SupportedNetwork
  route: string
  name: string
  imageURL: string
  bgColor: string
  primaryColor: string
  secondaryColor: string
  blurb?: string
}

export const EthereumNetworkInfo: NetworkInfo = {
  chainId: SupportedChainId.MAINNET,
  id: SupportedNetwork.ETHEREUM,
  route: '',
  name: 'Ethereum',
  bgColor: '#fc077d',
  primaryColor: '#fc077d',
  secondaryColor: '#2172E5',
  imageURL: ETHEREUM_LOGO_URL,
}

export const ArbitrumNetworkInfo: NetworkInfo = {
  chainId: SupportedChainId.ARBITRUM_ONE,
  id: SupportedNetwork.ARBITRUM,
  route: 'arbitrum',
  name: 'Arbitrum',
  imageURL: ARBITRUM_LOGO_URL,
  bgColor: '#0A294B',
  primaryColor: '#0490ED',
  secondaryColor: '#96BEDC',
}

export const OptimismNetworkInfo: NetworkInfo = {
  chainId: SupportedChainId.OPTIMISM,
  id: SupportedNetwork.OPTIMISM,
  route: 'optimism',
  name: 'Optimism',
  bgColor: '#F01B36',
  primaryColor: '#F01B36',
  secondaryColor: '#FB7876',
  imageURL: OPTIMISM_LOGO_URL,
}

export const PolygonNetworkInfo: NetworkInfo = {
  chainId: SupportedChainId.POLYGON,
  id: SupportedNetwork.POLYGON,
  route: 'polygon',
  name: 'Polygon',
  bgColor: '#8247e5',
  primaryColor: '#8247e5',
  secondaryColor: '#FB7876',
  imageURL: POLYGON_LOGO_URL,
  blurb: '',
}
export const CeloNetworkInfo: NetworkInfo = {
  chainId: SupportedChainId.CELO,
  id: SupportedNetwork.CELO,
  route: 'celo',
  name: 'Celo',
  bgColor: '#02502F',
  primaryColor: '#35D07F',
  secondaryColor: '#9ACDB2',
  imageURL: CELO_LOGO_URL,
  blurb: '',
}

export const BNBNetworkInfo: NetworkInfo = {
  chainId: SupportedChainId.BNB,
  id: SupportedNetwork.BNB,
  route: 'bnb',
  name: 'BNB Chain',
  bgColor: '#F0B90B',
  primaryColor: '#F0B90B',
  secondaryColor: '#F0B90B',
  imageURL: BNB_LOGO_URL,
  blurb: '',
}

export const ZkSyncTestnetNetworkInfo: NetworkInfo = {
  chainId: SupportedChainId.ZKSYNC_TESTNET,
  id: SupportedNetwork.ZKSYNC_TESTNET,
  route: 'zksync-testnet',
  name: 'ZKSync Era Testnet',
  bgColor: '#FFF',
  primaryColor: '#8586f1',
  secondaryColor: '#4a4d93',
  imageURL: ZKSYNC_LOGO_URL,
  blurb: '',
}

export const ZkSyncEraNetworkInfo: NetworkInfo = {
  chainId: SupportedChainId.ZKSYNC_MAINNET,
  id: SupportedNetwork.ZKSYNC_MAINNET,
  route: 'zksync',
  name: 'ZKSync Era Mainnet',
  bgColor: '#FFF',
  primaryColor: '#8586f1',
  secondaryColor: '#4a4d93',
  imageURL: ZKSYNC_LOGO_URL,
  blurb: '',
}

export const BaseTestnetNetworkInfo: NetworkInfo = {
  chainId: SupportedChainId.BASE_TESTNET,
  id: SupportedNetwork.BASE_TESTNET,
  route: 'base-testnet',
  name: 'Base Testnet',
  bgColor: '#FFF',
  primaryColor: '#0052ff',
  secondaryColor: '#ffffff',
  imageURL: BASE_LOGO_URL,
  blurb: '',
}

export const BaseMainnetNetworkInfo: NetworkInfo = {
  chainId: SupportedChainId.BASE_MAINNET,
  id: SupportedNetwork.BASE_MAINNET,
  route: 'base',
  name: 'Base',
  bgColor: '#FFF',
  primaryColor: '#0052ff',
  secondaryColor: '#ffffff',
  imageURL: BASE_LOGO_URL,
  blurb: '',
}

export const OpBnbTestnetNetworkInfo: NetworkInfo = {
  chainId: SupportedChainId.OPBNB_TESTNET,
  id: SupportedNetwork.OPBNB_TESTNET,
  route: 'opbnb-testnet',
  name: 'opBNB Testnet',
  bgColor: '#FFF',
  primaryColor: '#F0B90B',
  secondaryColor: '#ffffff',
  imageURL: BNB_LOGO_URL,
  blurb: '',
}

export const OpBnbMainnetNetworkInfo: NetworkInfo = {
  chainId: SupportedChainId.OPBNB_MAINNET,
  id: SupportedNetwork.OPBNB_MAINNET,
  route: 'opbnb',
  name: 'opBNB',
  bgColor: '#FFF',
  primaryColor: '#F0B90B',
  secondaryColor: '#ffffff',
  imageURL: BNB_LOGO_URL,
  blurb: '',
}

export const SUPPORTED_NETWORK_VERSIONS: NetworkInfo[] = [
  // EthereumNetworkInfo,
  // PolygonNetworkInfo,
  // OptimismNetworkInfo,
  // ArbitrumNetworkInfo,
  // CeloNetworkInfo,
  // BNBNetworkInfo,
  // ZkSyncTestnetNetworkInfo,
  ZkSyncEraNetworkInfo,
  // BaseTestnetNetworkInfo,
  BaseMainnetNetworkInfo,
  OpBnbMainnetNetworkInfo,
]

export const SUPPORTED_NETWORK_VERSIONS_BY_KEY: { [key: number]: NetworkInfo } = {
  [SupportedNetwork.ZKSYNC_MAINNET]: ZkSyncEraNetworkInfo,
  [SupportedNetwork.ZKSYNC_TESTNET]: ZkSyncTestnetNetworkInfo,
  [SupportedNetwork.BASE_MAINNET]: BaseMainnetNetworkInfo,
  [SupportedNetwork.BASE_TESTNET]: BaseTestnetNetworkInfo,
  [SupportedNetwork.OPBNB_MAINNET]: OpBnbMainnetNetworkInfo,
  [SupportedNetwork.OPBNB_TESTNET]: OpBnbTestnetNetworkInfo,
}

// used to mark unsupported tokens, these are hosted lists of unsupported tokens

export const UNSUPPORTED_LIST_URLS: string[] = []
export const OPTIMISM_LIST = 'https://static.optimism.io/optimism.tokenlist.json'
export const ARBITRUM_LIST = 'https://bridge.arbitrum.io/token-list-42161.json'
export const POLYGON_LIST =
  'https://unpkg.com/quickswap-default-token-list@1.2.2/build/quickswap-default.tokenlist.json'
export const CELO_LIST = 'https://celo-org.github.io/celo-token-list/celo.tokenlist.json'
export const BNB_LIST = 'https://raw.githubusercontent.com/plasmadlt/plasma-finance-token-list/master/bnb.json'
export const ZKSYNC_TESTNET_LIST =
  'https://raw.githubusercontent.com/derpdex-official/token-list/main/zksync-test.tokenlist.json'
export const ZKSYNC_MAINNET_LIST =
  'https://raw.githubusercontent.com/derpdex-official/token-list/main/zksync.tokenlist.json'
export const BASE_TESTNET_LIST =
  'https://raw.githubusercontent.com/derpdex-official/token-list/main/base-testnet.tokenlist.json'
export const BASE_MAINNET_LIST =
  'https://raw.githubusercontent.com/derpdex-official/token-list/main/base.tokenlist.json'
export const OPBNB_TESTNET_LIST =
  'https://raw.githubusercontent.com/derpdex-official/token-list/main/opbnb-testnet.tokenlist.json'
export const OPBNB_MAINNET_LIST =
  'https://raw.githubusercontent.com/derpdex-official/token-list/main/opbnb.tokenlist.json'

// lower index == higher priority for token import
export const DEFAULT_LIST_OF_LISTS: string[] = [
  ZKSYNC_TESTNET_LIST,
  ZKSYNC_MAINNET_LIST,
  BASE_MAINNET_LIST,
  BASE_TESTNET_LIST,
  OPBNB_TESTNET_LIST,
  OPBNB_MAINNET_LIST,
  ...UNSUPPORTED_LIST_URLS, // need to load unsupported tokens as well
]

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [ZKSYNC_MAINNET_LIST, BASE_MAINNET_LIST, OPBNB_MAINNET_LIST]

import { POOL_HIDE, QUERY_NETWORK } from '../constants/index'
import { SupportedNetwork } from 'constants/networks'
import { usePoolDatasByNetworkId } from 'data/pools/poolData'
import { useTopPoolAddressesByNetworkId } from 'data/pools/topPools'
import { useFetchGlobalChartData } from 'data/protocol/chart'
import { useFetchProtocolData, useFetchProtocolDataByNetworkId } from 'data/protocol/overview'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'state'
import { useClientsByNetwork } from 'state/application/hooks'
import {
  useAddPoolKeysByNetworkId,
  useAllPoolData,
  useAllPoolDataByNetworkId,
  useAllPoolDataRaw,
  useUpdatePoolDataByNetworkId,
} from 'state/pools/hooks'
import { useProtocolChartDataByNetworkId, useProtocolDataByNetworkId } from 'state/protocol/hooks'

const CallData = ({ networkId }: { networkId: number | SupportedNetwork }) => {
  const [protocolData, updateProtocolData] = useProtocolDataByNetworkId(networkId)

  const { dataClient, blockClient } = useClientsByNetwork(networkId)
  const { data: fetchedProtocolData, error, loading } = useFetchProtocolDataByNetworkId(networkId)

  useEffect(() => {
    if (protocolData === undefined && fetchedProtocolData && !loading && !error) {
      updateProtocolData(fetchedProtocolData)
    }
  }, [error, fetchedProtocolData, loading, protocolData, networkId])

  const [chartData, updateChartData] = useProtocolChartDataByNetworkId(networkId)
  const { data: fetchedChartData, error: chartError } = useFetchGlobalChartData(dataClient)

  useEffect(() => {
    if (chartData === undefined && fetchedChartData && !chartError) {
      updateChartData(fetchedChartData)
    } else {
    }
  }, [chartData, chartError, fetchedChartData, networkId])

  return <></>
}

const CallDataPools = ({ networkId }: { networkId: number | SupportedNetwork }) => {
  const updatePoolData = useUpdatePoolDataByNetworkId(networkId)

  const addPoolKeys = useAddPoolKeysByNetworkId(networkId)

  const allPoolData = useAllPoolDataByNetworkId(networkId)
  const { loading, error, addresses } = useTopPoolAddressesByNetworkId(networkId)
  // detect for which addresses we havent loaded pool data yet
  // add top pools on first load
  useEffect(() => {
    if (addresses && !error && !loading) {
      addPoolKeys(addresses)
    }
  }, [addPoolKeys, addresses, error, loading])

  useEffect(() => {
    addPoolKeys(POOL_HIDE[networkId])
  }, [addPoolKeys, networkId])

  const unfetchedPoolAddresses = useMemo(() => {
    return Object.keys(allPoolData).reduce((accum: string[], key) => {
      const poolData = allPoolData[key]
      if (!poolData.data || !poolData.lastUpdated) {
        accum.push(key)
      }
      return accum
    }, [])
  }, [allPoolData])

  const { error: poolDataError, loading: poolDataLoading, data: poolDatas } = usePoolDatasByNetworkId(
    unfetchedPoolAddresses ? unfetchedPoolAddresses : [],
    networkId
  )

  useEffect(() => {
    if (poolDatas && !poolDataError && !poolDataLoading) {
      updatePoolData(Object.values(poolDatas))
    }
  }, [poolDataError, poolDataLoading, poolDatas, networkId, updatePoolData])

  return <></>
}

export const StateUpdater = () => {
  const protocolData = useSelector((state: AppState) => state.protocol)

  const shouldFetch = useMemo(() => {
    const state = QUERY_NETWORK.map((n) => protocolData[n].data !== undefined)
    if (state.every((bool) => bool === true)) {
      return false
    } else {
      return true
    }
  }, [protocolData])

  const allPoolDataRaw = useAllPoolDataRaw()

  const [shouldFetchPools, setShouldFetchPools] = useState<boolean>(false)

  useEffect(() => {
    const state = QUERY_NETWORK.map((n) =>
      Object.keys(allPoolDataRaw.byAddress[n]).every((i) => allPoolDataRaw.byAddress[n][i].data !== undefined)
    )

    if (state.every((bool) => bool === true)) {
      setShouldFetchPools(false)
    } else {
      setShouldFetchPools(true)
    }
  }, [allPoolDataRaw])

  return (
    <>
      {shouldFetch && (
        <>
          {QUERY_NETWORK.map((n) => (
            <div key={n}>
              <CallData networkId={n} />
            </div>
          ))}
          {QUERY_NETWORK.map((n) => (
            <div key={n}>
              <CallDataPools networkId={n} />
            </div>
          ))}
        </>
      )}
      {shouldFetchPools && (
        <>
          {QUERY_NETWORK.map((n) => (
            <div key={n}>
              <CallDataPools networkId={n} />
            </div>
          ))}
        </>
      )}
    </>
  )
}
